/* src/Card.css */
.card {
    background-color: rgba(255, 255, 255, .3);
    border-radius: 8px;
    box-shadow: rgba(50, 50, 93, 0.1) 0px 30px 60px -12px, rgba(0, 0, 0, 0.2) 0px 18px 36px -18px;
    margin-bottom: 2rem;
    max-width: 672px;
    overflow: hidden;
    transition: transform 0.3s ease;
  }
  
  .card:hover {
    transform: scale(1.025);
  }
 
  .card-image {
    max-width: 672px;
  }
  
  .card-image img {
    display: block;
    height: auto;
    width: 100%;
  }
  
  .card-content {
    background-color: #FFFFFF;
    padding: 1.5rem;
  }
  
  .card-title {
    color: #4d4d4d;
    font-size: 1.125rem;
    margin: 0 0 0.5rem;
  }
  
  .card-description {
    color: #4d4d4d;
    font-size: 1rem;
    margin: 0 0 0.25rem;
  }
  