/* src/WelcomePage.css */
.welcome-page {
  align-items: flex-start;
  color: #FFFFFF;
  display: flex;
  height: 100vh;
  justify-content: center;
  padding: 2rem 1rem;
}

@media only screen and (min-width: 768px) {
  .welcome-page {
    align-items: center;
    padding: 0 1rem;
  }
}

.welcome-content {
  text-align: left;
}

.welcome-heading {
  font-size: 2.5rem;
  margin: 0 0 0.75rem;
  text-shadow: 1px 1px 3px rgba(0,0,0,.15);
}

.welcome-text {
  font-size: 1.125rem;
  line-height: 1.5;
  margin: 0 0 2rem;
  text-shadow: 1px 1px 3px rgba(0,0,0,.125);
}

.cta-button {
  display: block;
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: rgba(50, 50, 93, 0.1) 0px 30px 60px -12px, rgba(0, 0, 0, 0.2) 0px 18px 36px -18px;
  color: var(--primary-color);
  font-size: 1.125em;
  font-weight: bold;
  padding: 16px 24px;
  line-height: 1.5rem;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease;
}

.cta-button:hover {
  box-shadow: rgba(50, 50, 93, 0.3) 0px 30px 60px -12px, rgba(0, 0, 0, 0.5) 0px 18px 36px -18px;
  color: var(--primary-color-darker);
}
