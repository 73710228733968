.theme-default {
  --primary-color: #2980b9;
  --primary-color-darker: #1d6ca1;
  --primary-color-lighter: #7ccaff;
}

.theme-alternate {
  --primary-color: #6029b9;
  --primary-color-darker: #4f1e9e;
  --primary-color-lighter: #b183fa;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: radial-gradient(farthest-corner, var(--primary-color) 0%, var(--primary-color-lighter) 100%);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
          "Segoe UI Symbol";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: var(--primary-color);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
